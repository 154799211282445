import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Sidebar from "../components/homeMenu/sideBar"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MainBanner from '../components/MainBanner'
import Projetos from "../components/Projects"
import { toggleWhiteSidebar } from "../state/app"
import { InView } from 'react-intersection-observer'
import { connect } from 'react-redux'

const ImoveisRetomaPage = ({ dispatch, location }) => {
  const { t } = useTranslation()

  var dataImoveisRetoma = t("imoveisretoma", { returnObjects: true })
  var dataGlobal = t("global", { returnObjects: true })

  return (
    <Layout
      data={dataGlobal}
      location={location}
    >
      <Seo title="Portfólio" />
      <Sidebar
        content={dataImoveisRetoma.sidebar}
        projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
        projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
        whiteMobileNavbar
      />
      {/* CHANGE SIDE BAR COLOR TO WHITE */}
      <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
        <MainBanner data={dataImoveisRetoma.menu} />
      </InView>
      <Projetos
        data={dataImoveisRetoma.cards}
        filters
        portfolio
      />
    </Layout>
  )
}

export default connect()(ImoveisRetomaPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["portfolio", "global", "projetosVenda", "portfolio", "imoveisretoma"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`